import React, { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@mui/material/styles/styled"

export const Footer = memo(() => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
  )
  return (
    <Wrapper>
      <Copyright>&copy; 2022 {site.siteMetadata.author} - {site.siteMetadata.title}</Copyright>
    </Wrapper>
  )
})

const Copyright = styled('small')(({ theme }) => {
  const isDark = theme.palette.mode == 'dark'
  const textColor = theme.palette.text.primary
  const backgroundColor = isDark ? theme.palette.primary.dark : theme.palette.primary.light
  return({
  ...theme.typography.caption,
  display: 'block',
  width: '100%',
  height: '3em',
  padding: 0,
  margin: 0,
  backgroundColor: backgroundColor,
  textAlign: 'center',
  lineHeight: '3em',
  color: textColor,
})});
const Wrapper = styled('footer')(({ theme }) => ({
  display: 'block',
  width: '100%',
  paddingTop: theme.spacing(3),
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.background.z1
}));