import grey from "@mui/material/colors/grey";
import lightBlue from "@mui/material/colors/lightBlue";
import teal from "@mui/material/colors/teal";
import createTheme from "@mui/material/styles/createTheme";
import { alpha, CSSObject } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";


// フォントを設定
const fontFamily = [
  'Noto Sans JP',
  'メイリオ',
  'ＭＳ Ｐゴシック',
  'sans-serif'
].join(',');

const lightPalette: PaletteOptions = {
  primary: {
    light: 'hsl(210, 70%, 70%)',
    main: 'hsl(230, 45%, 45%)',
    dark: 'hsl(230, 45%, 25%)'
  },
  background: {
    z1: grey[50],
    z2: grey[100]
  }
}

const darkPalette: PaletteOptions = {
  primary: teal,
  secondary: lightBlue,
  text: {
    accent: alpha(teal.A200, 0.8),
    link: '#8ab4f8',
    visitedLink: '#C58af9' 
  },
  background: {
    z1: grey[900],
    z2: grey[800]
  }
}

const scrollbarStyle: CSSObject = {
  '::-webkit-scrollbar': {
    width: '16px',
    backgroundColor: 'rgba(255, 255, 255, 0.09)'
  },
  '::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent'
  },
  '::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb': {
    border: '4px solid transparent',
    backgroundClip: 'padding-box',
    borderRadius: '5px'
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  },
  '::-webkit-scrollbar-thumb': {
    minHeight: '40px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)'
  }

}

export const theme = createTheme({
  typography: {
    fontFamily: fontFamily,
  },
  palette: {
    mode: 'dark',
    ...darkPalette,
    // ...lightPalette
  },
  mixins: {
    toolbar: {
      minHeight: 64
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: scrollbarStyle
    },
  },
});