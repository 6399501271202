import React, { memo } from 'react';
import { Header } from './header';
import { theme } from '../styles/theme';
import { Footer } from './footer';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useWindowScroll, useWindowSize } from 'react-use';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import styled from '@mui/material/styles/styled';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

const RootLayout: React.FC = memo(({ children }) => {
  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <Contents>
        <StickyHeader/>
          {children}
        <Footer/>
        <GoToTopButton/>
      </Contents>
    </ThemeProvider >
  )
})
// gatsby-plugin-layoutのために
export default RootLayout

const Contents = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8),
  '& .container': {
    maxWidth: 900
  }
}))

const StickyHeader = styled(Header)(({ theme }) => ({
  position: 'fixed',
  top: `-${theme.spacing(2)}`,
  zIndex: theme.zIndex.appBar,
  height: theme.spacing(10),
  paddingTop: theme.spacing(2)
}));

const GoToTopButton = memo(()=>{
  const { height } = useWindowSize() 
  const { y } = useWindowScroll()
  const isShow = y > height / 2
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const handleClick = () => {
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return( 
    <Zoom
      in={isShow}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${isShow ? transitionDuration.exit : 0}ms`,
      }}
      unmountOnExit
    >
      <StyledFab onClick={handleClick}>
        <UpIcon />
      </StyledFab>
    </Zoom>
  )
})

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
}));