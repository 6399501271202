import React, { memo } from "react"
import { Link } from "gatsby";
import styled from "@mui/material/styles/styled";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
type Route = {
  name: string,
  path: string
} 

const routes: Route[] = [
  // { name: 'ABOUT', path: '/about' },
  // { name: 'GUIDE', path: '/guide' },
  // { name: 'BLOG', path: '/blog' }
]

export const NavigationBar: React.FC = memo(() => {
  const listItems = routes.map((value) => {
    return <ListItem key={value.name}><ListItemLink to={value.path} activeClassName="active" children={value.name}/></ListItem>
  })
  return (
    <Wrapper>
      <List children={listItems}/>
    </Wrapper>
  )
})

const Wrapper = styled('nav')(({ theme }) => ({
  display: 'block',
  margin: 0
}));

const List = styled('ul')(({ theme }) => ({
  display: 'flex',
  margin: 0,
  padding: 0,
  justifyContent: 'start',
  listStyleType: 'none',
}));

const ListItem = styled(ListItemButton)(({ theme }) => ({
  flex: 0,
  padding: 0
}));

const ListItemLink = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle1,
  display: 'block',
  width: '100%',
  height: '100%',
  padding: theme.spacing(2),
  lineHeight: theme.spacing(4),
  textDecoration: 'none',
  textAlign: 'center',
  color: 'inherit',
}));