import React, { CSSProperties, memo } from "react"
import { Link } from "gatsby";
import styled from "@mui/material/styles/styled";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import Home from "@mui/icons-material/Home";

export const HomeButton: React.FC<Partial<Element & CSSProperties>> = memo((props) => {
  return (
    <StyledLink component={Link} to={'/'} activeClassName="active" className={props.className} style={props} aria-label="トップページへ">
      <StyledHome/>
    </StyledLink>
  )
})

const StyledLink = styled(ListItemButton, {shouldForwardProp: (prop) => prop !== "style"})<{style: CSSProperties}>(({ theme, style }) => ({
  display: "block",
  width: "100%",
  height: "100%",
  padding: 0,
  margin: 0,
  color: 'inherit',
  ...style,
}));

const StyledHome = styled(Home)(({ theme }) => ({
  display: "block",
  width: "100%",
  height: "100%",
}));