import React, { CSSProperties, memo } from "react"
import { NavigationBar } from "../navigation/navigation-bar";
import { HomeButton } from "../navigation/home-button";
import styled from "@mui/material/styles/styled";
import { alpha } from "@mui/material/styles";
export const Header: React.FC<Partial<Element & CSSProperties>> = memo((props) => {
  return (
    <Wrapper className={props.className} style={props}>
      <div style={{ display: 'flex', margin: '0 auto'}} className="container">
        <StyledHomeButton/>
        <NavigationBar />
      </div>
    </Wrapper>
  )
})

const Wrapper = styled('header', { shouldForwardProp: (prop) => prop !== "style" })<{ style: CSSProperties }>(({ theme, style }) => {
  const isDark = theme.palette.mode == 'dark'
  const textColor = isDark ? theme.palette.primary.light : theme.palette.primary.main
  const backgroundColor = theme.palette.background.z1
  return ({
    width: '100%',
    height: theme.spacing(8),
    margin: 0,
    color: textColor,
    backgroundColor: alpha(backgroundColor, 0.7),
    backdropFilter: `blur(${theme.spacing(1)})`,
    ...style
})});

const StyledHomeButton = styled(HomeButton)(({ theme }) => ({
  flex: '0 0 auto',
  width: theme.spacing(16),
  height: theme.spacing(8),
  padding:theme.spacing(0.7)
}));